import React from 'react'
import type { FC } from 'react'
import { Box } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import AccordionCarousel from 'src/components/cyber-monday-avon/above/AccordionCarousel'
import ShelfByPercent from 'src/components/cyber-monday-avon/above/ShelfByPercent'
import CountdownShelf from 'src/components/cyber-monday-avon/above/CountdownShelf'

import type { ProductPageType } from '../product/components/typings/product'

interface Props {
  data: {
    vtex: {
      products: ProductPageType[]
      productSearch: { products: ProductPageType[] }
    }
    cmsInstitutionalPage: {
      sections: Array<{
        name: string
        props: {
          title: string
          searchParams: {
            collection: string
            from: number
            to: number
            hideUnavailableItems: boolean
            orderBy: string
          }

          content: string

          allItems: Array<{
            sources: Array<{ srcSet: string }>
            alt: string
          }>
        }
      }>
    }
  }
}

const Above: FC<Props> = ({
  data: {
    vtex: { productSearch },
    cmsInstitutionalPage,
  },
}) => {
  return (
    <Box>
      <EventPageView
        type="OTHER"
        title={cmsInstitutionalPage.seo.siteMetadataWithSlug.title}
      />
      <AccordionCarousel sections={cmsInstitutionalPage?.sections} />
      <ShelfByPercent sections={cmsInstitutionalPage?.sections} />
      <CountdownShelf
        products={productSearch.products}
        sections={cmsInstitutionalPage?.sections}
      />
    </Box>
  )
}

export default Above
