import React from 'react'
import { Box, Flex, Image, Text } from '@vtex/store-ui'
import CountDown from 'src/components/common/CountDown'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'
import type { ProductPageType } from 'src/views/product/components/typings/product'

import styles from './styles.json'

interface Props {
  sections: Array<{
    props: {
      content: string
    }
  }>
  products: ProductPageType[]
}

const CountdownShelf = ({ sections, products }: Props) => {
  const cmsDate = JSON.parse(sections?.[4].props.content ?? '')

  return (
    <Box sx={styles}>
      <Flex sx={styles.container}>
        <Box sx={styles.imageContent}>
          <Image
            src="https://avongroup.vteximg.com.br/arquivos/basket-blackfriday-image.png"
            alt="Produtos em desconto"
            title="Produtos em desconto"
          />
        </Box>
        <Box sx={styles.textContent}>
          <CountDown countDate={cmsDate.blocks[0].text} />
          <Box sx={styles.textContent.title}>
            <Text as="h3">Oferta Relâmpago</Text>
          </Box>
          <Box sx={styles.textContent.description}>
            <Text as="p">
              Produtos de diversas categorias com super descontos disponíveis
              por apenas <Text as="strong">24 horas</Text>.
            </Text>
            <Text as="p">Corra para aproveitar!</Text>
          </Box>
        </Box>
        <Box sx={styles.shelfContent}>
          <Shelf
            {...sections[6].props}
            showArrows
            products={products}
            ProductSummary={ProductSummary}
            pageSizes={[1, 2]}
            isStaticShelf
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default CountdownShelf
