import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Image, Text, Button, SuspenseDevice } from '@vtex/store-ui'
import Shelf from 'src/components/product/ProductSlider'
import ProductSummary from 'src/components/product/ProductSummaryWhite'
import { ArrowLeft } from 'src/components/icons'
import type { ProductPageType } from 'src/views/product/components/typings/product'

import SpotWatch from './SpotWatch'
import styles from './styles.json'

interface Props {
  products?: ProductPageType[]
  sections: Array<{
    name: string
    props: {
      title: string
      searchParams: {
        collection: string
        from: number
        to: number
        hideUnavailableItems: boolean
        orderBy: string
      }
    }
  }>
}

const ShelfByPercentDiscount = ({ sections }: Props) => {
  const [viewMore, setViewMore] = useState<boolean>(false)
  const [current, setCurrent] = useState<number>(0)

  const handleViewMore = () => {
    setViewMore(true)
  }

  const handleViewLess = () => {
    setViewMore(false)
  }

  const discountShelfData = [
    {
      id: 1,
      category: '10% off',
      imageUrl:
        'https://avongroup.vteximg.com.br/arquivos/black-friday-kit-descontos.png',
      imageAlt: 'até 10% off',
      imageTitle: 'até 10% off',
      link: '/cyber-monday-avon/10',
      text: <Text as="h3">Veja mais</Text>,
    },
    {
      id: 2,
      category: '20% off',
      imageUrl:
        'https://avongroup.vteximg.com.br/arquivos/black-friday-kit-descontos.png',
      imageAlt: 'até 20% off',
      imageTitle: 'até 20% off',
      link: '/cyber-monday-avon/20',
      text: <Text as="h3">Veja mais</Text>,
    },
    {
      id: 3,
      category: '50% off',
      imageUrl:
        'https://avongroup.vteximg.com.br/arquivos/black-friday-kit-descontos.png',
      imageAlt: 'até 50% off',
      imageTitle: 'até 50% off',
      link: '/cyber-monday-avon/50',
      text: <Text as="h3">Veja mais</Text>,
    },
    {
      id: 4,
      category: '70% off',
      imageUrl:
        'https://avongroup.vteximg.com.br/arquivos/black-friday-kit-descontos.png',
      imageAlt: 'até 70% off',
      imageTitle: 'até 70% off',
      link: '/cyber-monday-avon/70',
      text: <Text as="h3">Veja mais</Text>,
    },
  ]

  const prevSlide = () => {
    setCurrent((state) =>
      state === 0 ? discountShelfData.length - 1 : state - 1
    )
  }

  const nextSlide = () => {
    setCurrent((state) =>
      state === discountShelfData.length - 1 ? 0 : state + 1
    )
  }

  return (
    <Box sx={styles}>
      <Box as="section" sx={styles.container}>
        <Box sx={styles.textContent}>
          <Box sx={styles.textContent.title}>
            <Text as="h2">Cyber Monday Avon</Text>
          </Box>
          <Box sx={styles.textContent.description}>
            <Text>
              Na Cyber Monday , onde você vai encontrar as melhores opções!
              {!viewMore && (
                <Button onClick={handleViewMore}>...Ver mais.</Button>
              )}
              <Box
                sx={{
                  display: viewMore ? 'block' : 'none',
                }}
              >
                A melhor Cyber Monday Maquiagem e cosméticos você vai encontrar
                é na Avon.
                <Button onClick={handleViewLess}>...Ver menos.</Button>
              </Box>
            </Text>
          </Box>
        </Box>
        <Box sx={styles.shelfSelector}>
          <SuspenseDevice device="mobile" fallback={null}>
            <Box sx={styles.controlsArrow}>
              <Button
                value={current + 1 === 1 ? 'tab4' : `tab${current}`}
                data-input="prev"
                role="button"
                onClick={prevSlide}
              >
                <ArrowLeft />
              </Button>

              <Button
                value={current + 1 === 4 ? 'tab1' : `tab${current + 2}`}
                data-input="next"
                role="button"
                onClick={nextSlide}
              >
                <ArrowLeft />
              </Button>
            </Box>
          </SuspenseDevice>
          <Flex sx={styles.shelfSelector.cardContainer}>
            {discountShelfData.map((item, index: number) => (
              <Box
                key={index}
                sx={
                  index === current
                    ? styles.shelfSelector.itemActive
                    : styles.shelfSelector.itemInactive
                }
              >
                <Link to={`${item.link}`}>
                  <Box
                    key={item.id}
                    sx={{
                      margin: ['0 auto', 'initial'],
                      width: ['70%', 'initial'],
                      border: 'none',
                      outline: 'none',
                      background: 'none',
                      cursor: 'pointer',

                      '&:hover': {
                        transition: 'all 0.3s',
                        span: { display: ['flex'] },
                        h3: { fontWeight: [700] },
                      },
                    }}
                  >
                    <Box sx={styles.shelfSelector.image}>
                      <Image
                        src={item.imageUrl}
                        alt={item.imageAlt}
                        title={item.imageTitle}
                      />
                      <SpotWatch />
                    </Box>
                    <Box sx={styles.shelfSelector.textContainer}>
                      <Box sx={styles.shelfSelector.border}>
                        <Box sx={styles.shelfSelector.text}>{item.text}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </Box>
            ))}
          </Flex>
        </Box>
        <SuspenseDevice device="mobile" fallback={null}>
          <Box sx={styles.shelfSelector.dots}>
            {discountShelfData.map((_, index: number) => (
              <Box
                key={index}
                sx={
                  current === index
                    ? styles.shelfSelector.dots.itemActive
                    : styles.shelfSelector.dots.item
                }
              />
            ))}
          </Box>
        </SuspenseDevice>
        <Box sx={styles.shelfSelector.shelf}>
          <Shelf
            {...sections[5].props}
            showArrows
            ProductSummary={ProductSummary}
          />
          <Box sx={styles.shelfSelector.buttonLink}>
            <Link to="/cyber-monday-avon/produtos">Ver todos</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ShelfByPercentDiscount
