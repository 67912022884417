import React from 'react'
import { Box, Image, Text, Flex, Link } from '@vtex/store-ui'
import * as Tabs from '@radix-ui/react-tabs'
import {
  InstagramNonFilledIcon,
  TwitterWithoutLinkIcon,
  TikTokIcon,
} from 'src/components/icons'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import styles from './styles.json'

const TabButton = () => (
  <Tabs.List>
    <Tabs.Trigger value="tab1">
      <InstagramNonFilledIcon />
    </Tabs.Trigger>
    <Tabs.Trigger value="tab2">
      <TwitterWithoutLinkIcon />
    </Tabs.Trigger>
    <Tabs.Trigger value="tab3">
      <TikTokIcon />
    </Tabs.Trigger>
  </Tabs.List>
)

const FollowOnSocialMedia = () => {
  const { isMobile } = useDeviceDetect()

  return (
    <Box sx={styles}>
      <Box sx={styles.container}>
        <Box sx={styles.titleContent}>
          <Text as="h3">
            <Text as="strong">Siga a Avon nas redes sociais</Text>
            <Text as="span">Para ficar por dentro de tudo</Text>
          </Text>
        </Box>
        <Box sx={styles.socialMediaContent}>
          <Tabs.Root defaultValue="tab1">
            <Tabs.Content value="tab1">
              <Flex sx={styles.socialMediaContent.item}>
                <Box
                  as="picture"
                  sx={styles.socialMediaContent.item.imageContent}
                >
                  <Image
                    src={
                      isMobile
                        ? 'https://avongroup.vteximg.com.br/arquivos/screen-instagram-mob.png'
                        : 'https://avongroup.vteximg.com.br/arquivos/screen-instagram-desk.png'
                    }
                    alt="Instagram Avon"
                    title="Instagram Avon"
                  />
                </Box>
                <Box sx={styles.socialMediaContent.item.textContent}>
                  <TabButton />
                  <Box as="article">
                    <Text as="h4">Trends que você vai amar compartilhar</Text>
                    <Text as="p">
                      <Text as="strong">Siga a Avon no Instagram</Text> para não
                      perder nenhuma novidade! Lá, você encontra muito feed,
                      stories e reels com os melhores conteúdos para você estar
                      sempre por dentro de tudo que rola por aqui.
                    </Text>
                    <Link
                      href="https://www.instagram.com/avonbrasil"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Quero Acompanhar!
                    </Link>
                  </Box>
                </Box>
              </Flex>
            </Tabs.Content>
            <Tabs.Content value="tab2">
              <Flex sx={styles.socialMediaContent.item}>
                <Box
                  as="picture"
                  sx={styles.socialMediaContent.item.imageContent}
                >
                  <Image
                    src={
                      isMobile
                        ? 'https://avongroup.vteximg.com.br/arquivos/screen-twitter-mob.png'
                        : 'https://avongroup.vteximg.com.br/arquivos/screen-twitter-desk.png'
                    }
                    alt="Twitter Avon"
                    title="Twitter Avon"
                  />
                </Box>
                <Box sx={styles.socialMediaContent.item.textContent}>
                  <TabButton />
                  <Box as="article">
                    <Text as="h4">
                      O assunto do momento é a Cyber Monday, bebê!
                    </Text>
                    <Text as="p">
                      <Text as="strong">Siga a Avon no Twitter</Text> e fique
                      por dentro de todas as{' '}
                      <Text as="strong">ofertas e promoções em tempo real</Text>
                      . Vai ter chuva de tweets, sim!
                    </Text>
                    <Link
                      href="https://twitter.com/AvonBR"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Quero Acompanhar!
                    </Link>
                  </Box>
                </Box>
              </Flex>
            </Tabs.Content>
            <Tabs.Content value="tab3">
              <Flex sx={styles.socialMediaContent.item}>
                <Box
                  as="picture"
                  sx={styles.socialMediaContent.item.imageContent}
                >
                  <Image
                    src={
                      isMobile
                        ? 'https://avongroup.vteximg.com.br/arquivos/screen-tiktok-mob.png'
                        : 'https://avongroup.vteximg.com.br/arquivos/screen-tiktok-desk.png'
                    }
                    alt="TikTok Avon"
                    title="TikTok Avon"
                  />
                </Box>
                <Box sx={styles.socialMediaContent.item.textContent}>
                  <TabButton />
                  <Box as="article">
                    <Text as="h4">Para se jogar nas novidades</Text>
                    <Text as="p">
                      Rolou oferta em um produto que você nunca experimentou, e
                      não sabe como usar?{' '}
                      <Text as="strong">Siga a Avon no Tik Tok</Text> e confira
                      diversas <Text as="strong">dicas de uso</Text> de diversos
                      produtos para você se inspirar e se jogar!
                    </Text>
                    <Link
                      href="https://www.tiktok.com/@avonbrasil"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Quero Acompanhar!
                    </Link>
                  </Box>
                </Box>
              </Flex>
            </Tabs.Content>
          </Tabs.Root>
        </Box>
      </Box>
    </Box>
  )
}

export default FollowOnSocialMedia
