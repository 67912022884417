import React from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import Seo from 'src/views/cyber-monday-avon/Seo'
import AboveTheFold from 'src/views/cyber-monday-avon/AboveTheFold'
import BelowTheFold from 'src/views/cyber-monday-avon/BelowTheFold'

import type { CyberMondayQueryQuery } from './__generated__/CyberMondayQuery.graphql'

export type Props = GatsbyPageProps<CyberMondayQueryQuery>

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
  },
}

const Page: FC<CyberMondayQueryQuery> = (props) => {
  return (
    <Layout>
      <View {...ViewComponents} data={props} />
    </Layout>
  )
}

export const query = graphql`
  query CyberMondayQuery(
    $from: Int!
    $to: Int!
    $collection: String!
    $countdownShelf: String!
    $orderBy: String
    $orderByCountdownShelf: String!
    $hideUnavailableItems: Boolean!
    $hideUnavailableItemsCountdownShelf: Boolean!
  ) {
    cmsInstitutionalPage(name: { eq: "Cyber Monday" }) {
      sections {
        name
        props: data
      }
      seo {
        siteMetadataWithSlug {
          description
          slug
          title
          titleTemplate
        }
      }
    }

    vtex {
      products(
        from: $from
        to: $to
        collection: $collection
        orderBy: $orderBy
        hideUnavailableItems: $hideUnavailableItems
      ) {
        ...ProductSummary_product
      }

      productSearch(
        from: 0
        to: 1
        selectedFacets: [{ key: "productClusterIDS", value: $countdownShelf }]
        orderBy: $orderByCountdownShelf
        hideUnavailableItems: $hideUnavailableItemsCountdownShelf
      ) {
        products {
          ...ProductSummary_product
        }
      }
    }
  }
`

export default Page
