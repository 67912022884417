import React, { useState, useEffect } from 'react'
import { Box, Image, Flex, Text, Button } from '@vtex/store-ui'
import { Link } from 'gatsby'
import { ArrowLeft, TouchIcon } from 'src/components/icons'

import styles from './styles.json'

interface Props {
  sections: Array<{
    props: {
      allItems: Array<{
        sources: Array<{ srcSet: string }>
        alt: string
      }>
    }
  }>
}

const AccordionCarousel = ({ sections }: Props) => {
  const [hasWindow, setHasWindow] = useState<boolean>(false)
  const [width, setWidth] = useState<number>(0)
  const [current, setCurrent] = useState<number>(0)
  const banners = sections?.[0].props.allItems
  const bannersMobile = sections?.[1].props.allItems

  useEffect(() => {
    if (window !== undefined) {
      setHasWindow(true)
      setWidth(window.innerWidth)
    }
  }, [])

  const prevSlide = () => {
    setCurrent(current === 0 && banners ? banners?.length - 1 : current - 1)
  }

  const nextSlide = () => {
    setCurrent(banners && current === banners?.length - 1 ? 0 : current + 1)
  }

  return (
    <Box sx={styles}>
      {hasWindow && width <= 680 && (
        <Box sx={styles.container}>
          <Box sx={styles.container.controls}>
            <Button onClick={prevSlide}>
              <ArrowLeft />
            </Button>
            <Button onClick={nextSlide}>
              <ArrowLeft />
            </Button>
          </Box>
          <Flex sx={styles.container.carousel}>
            {bannersMobile?.map((image, index: number) => (
              <Box
                key={index}
                sx={
                  index === current
                    ? styles.container.carousel.itemActive
                    : styles.container.carousel.itemInactive
                }
              >
                <Link to={image.href}>
                  <Box as="picture">
                    <Image
                      src={image.sources[0].srcSet}
                      alt={image.alt}
                      title={image.alt}
                    />
                  </Box>
                </Link>
                <Box sx={styles.container.carousel.indicator}>
                  <Text as="strong">0{index + 1}</Text>
                  <Box>
                    <TouchIcon />
                  </Box>
                  <Box />
                </Box>
              </Box>
            ))}
          </Flex>
          <Box sx={styles.container.dots}>
            {banners?.map((_, index: number) => (
              <Box
                key={index}
                sx={
                  current === index
                    ? styles.container.dots.itemActive
                    : styles.container.dots.item
                }
              />
            ))}
          </Box>
        </Box>
      )}
      {hasWindow && width >= 1280 && (
        <Box sx={styles.container}>
          <Box sx={styles.container.controls}>
            <Button onClick={prevSlide}>
              <ArrowLeft />
            </Button>
            <Button onClick={nextSlide}>
              <ArrowLeft />
            </Button>
          </Box>
          <Flex sx={styles.container.carousel}>
            {banners?.map((image, index: number) => (
              <Box
                key={index}
                sx={
                  index === current
                    ? styles.container.carousel.itemActive
                    : styles.container.carousel.itemInactive
                }
              >
                <Link to={image.href}>
                  <Box as="picture">
                    {index === current ? (
                      <Image
                        src={image.sources[0].srcSet}
                        alt={image.alt}
                        title={image.alt}
                      />
                    ) : (
                      <Image
                        src={image.sources[1].srcSet}
                        alt={image.alt}
                        title={image.alt}
                      />
                    )}
                  </Box>
                </Link>
                <Box sx={styles.container.carousel.indicator}>
                  <Text as="strong">0{index + 1}</Text>
                  <Box>
                    <TouchIcon />
                  </Box>
                  <Box />
                </Box>
              </Box>
            ))}
          </Flex>
          <Box sx={styles.container.dots}>
            {banners?.map((_, index: number) => (
              <Box
                key={index}
                sx={
                  current === index
                    ? styles.container.dots.itemActive
                    : styles.container.dots.item
                }
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default AccordionCarousel
