import React, { useMemo } from 'react'
import { Box, Flex } from '@vtex/store-ui'
import { Link } from 'gatsby'
import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import { withArtDirection } from 'gatsby-plugin-image'
import imagesConf from 'src/images/config'

import styles from './styles.json'

interface Banner {
  desktop: { srcSet: string }
  mobile: { srcSet: string }
  alt: string
  image: IGatsbyImageData
  url: string
}

const useBanner = (banner: Banner) => {
  const getImageData = useGetThumborImageData()

  return useMemo(() => {
    if (
      banner == null ||
      banner.desktop?.srcSet == null ||
      banner.mobile?.srcSet == null
    ) {
      return banner
    }

    const { desktop, mobile } = banner

    const desktopImage = getImageData({
      baseUrl: desktop.srcSet,
      ...imagesConf['departmentBanner.desktop'],
    })

    const mobileImage = getImageData({
      baseUrl: mobile.srcSet,
      ...imagesConf['departmentBanner.mobile'],
    })

    const image = withArtDirection(mobileImage, [
      {
        media: '(min-width: 40em)',
        image: desktopImage,
      },
    ])

    return {
      ...banner,
      image,
    }
  }, [banner, getImageData])
}

interface Props {
  sections: Array<{
    props: Banner
  }>
}

const DoubleBanners = ({ sections }: Props) => {
  const firstBanner = useBanner(sections[9].props)
  const secondBanner = useBanner(sections[10].props)

  if (firstBanner == null || secondBanner == null) {
    return null
  }

  const isFirstBanner = firstBanner.desktop.srcSet !== undefined
  const isSecondBanner = secondBanner.desktop.srcSet !== undefined

  return (
    <Box sx={styles}>
      <Box sx={styles.container}>
        <h1>APROVEITE MAIS DESCONTOS</h1>
        <Flex sx={styles.content}>
          {isFirstBanner && (
            <Link to={firstBanner.url}>
              <Box as="picture">
                {firstBanner.image.images.sources?.map((source) => (
                  <source key={source.srcSet} {...source} />
                ))}
                <img
                  alt={firstBanner.alt}
                  height={firstBanner.image.height}
                  width={firstBanner.image.width}
                  title={firstBanner.alt}
                  {...firstBanner.image.images.fallback}
                />
              </Box>
            </Link>
          )}
          {isSecondBanner && (
            <Link to={secondBanner.url}>
              <Box as="picture">
                {secondBanner.image.images.sources?.map((source) => (
                  <source key={source.srcSet} {...source} />
                ))}
                <img
                  alt={secondBanner.alt}
                  height={secondBanner.image.height}
                  width={secondBanner.image.width}
                  title={secondBanner.alt}
                  {...secondBanner.image.images.fallback}
                />
              </Box>
            </Link>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default DoubleBanners
