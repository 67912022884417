import React from 'react'
import type { FC } from 'react'
import CategoryShelf from 'src/components/cyber-monday-avon/below/CategoryShelf'
import DoubleBanners from 'src/components/cyber-monday-avon/below/DoubleBanners'
import FollowOnSocialOnMedia from 'src/components/cyber-monday-avon/below/FollowOnSocialMedia'
import Questions from 'src/components/cyber-monday-avon/below/Questions'
import type { IGatsbyImageData } from 'gatsby-plugin-image'

interface Props {
  data: {
    cmsInstitutionalPage: {
      sections: Array<{
        props: {
          desktop: { srcSet: string }
          mobile: { srcSet: string }
          alt: string
          image: IGatsbyImageData

          content: string

          allItems: Array<{
            sources: Array<{ srcSet: string }>
            alt: string
          }>
        }
      }>
    }
  }
}

const Below: FC<Props> = ({
  data: {
    cmsInstitutionalPage: { sections },
  },
}) => (
  <div>
    <CategoryShelf sections={sections} />
    <DoubleBanners sections={sections} />
    <FollowOnSocialOnMedia />
    <Questions />
  </div>
)

export default Below
